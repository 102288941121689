import React from 'react';

export default function () {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-16">
      <div className=" max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width="404"
          height="404"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true">
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse">
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"></rect>
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"></rect>
        </svg>

        <div className="relative ">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h2 className="text-2xl font-extrabold text-primarycolor tracking-tight sm:text-3xl">
                Nous formons nos serruriers à l’excellence
              </h2>
              <div className="mt-3 text-lg text-gray-600">
                <p>
                  La priorité de Super Serrurier est votre sécurité. C’est
                  pourquoi nous formons nous même serruriers. Ils bénéficient
                  d’une formation complète qui couvre tous les domaines, et sont
                  doublés pendant six mois sur le terrain, afin d’assurer la
                  meilleure qualité de service. En nous choisissons, vous avez
                  la garanti de travailler avec les meilleur srruriers de
                  romandie.
                </p>
                <p className="mt-2">
                  C’est d’ailleurs parceque nous sommes les meilleures que nos
                  interventions durent en moyenne 20 minutes de plus que les
                  autres serruriers, car les super serruriers font un travail
                  honnête et consciencieux pour ne pas dégrader votre matériel,
                  et augmenter vos coûts.
                </p>
                <p className="mt-2">
                  Par exemple, losque la plus part des serruriers préfèrent
                  casser la sérrures en cas de perte de clefs, nous essayons de
                  changer le cylindre, qui coûte entre 40 CHF et 80 CHF et
                  évitons d’avoir à changer la serrure qui coûtent très souvent
                  plus de 300 CHF.
                </p>
              </div>
            </div>

            <div className=" -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width="784"
                height="244"
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true">
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse">
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"></rect>
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"></rect>
              </svg>
              <img
                className="relative mx-auto"
                width="490"
                src="https://res.cloudinary.com/dnzgotmwo/image/upload/v1606606743/super/A_propos_ak2tjr.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
