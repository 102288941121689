import * as React from 'react';

import Navbar from './Navbar';
import Footer from './Footer';
import { BiSolidPhoneCall } from 'react-icons/bi';

export default function Layout({ children, callBtn = true }) {
  return (
    <div className="relative">
      <Navbar />
      {children}
      {callBtn && (
        <a
          id="float-call-button"
          href="tel:0791304768"
          className="fixed flex justify-center items-center bottom-10 lg:bottom-20 right-8 rounded-full h-14 w-14 z-20 hover:h-16 hover:w-16 bg-primarycolor"
        >
          <BiSolidPhoneCall size={24} color="fff" />
        </a>
      )}
      <Footer />
    </div>
  );
}
