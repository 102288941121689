import React from 'react';
import { Helmet } from 'react-helmet';

import AboutHeader from '../components/AboutHeader';
import Stats from '../components/Stats';
import AboutFormation from '../components/AboutFormation';

import Favicon from '../images/favicon.ico';
import Layout from '../components/Layout';

const Apropos = () => {
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>
          A propos de Super Serrurier - le serrurier le mieux noté de Suisse
        </title>
        <meta
          name="description"
          content="Un des rares formateurs d’artisans serruriers de Suisse encore en activité, découvrez notre histoire et notre philosophie."
        />
      </Helmet>

      <AboutHeader />
      <Stats />
      <AboutFormation />
    </Layout>
  );
};
export default Apropos;
