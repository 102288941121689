import React from 'react';

export default function(props) {
  return (
    <div className="py-16 overflow-hidden bg-gray-50  lg:py-8">
      <div className=" max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full top-72 transform -translate-x-1/2 -translate-y-1/4"
          width="404"
          height="484"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              ></rect>
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          ></rect>
        </svg>

        <div>
          <h1 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-textcolor sm:text-4xl">
            Expert en
            <span className="text-primarycolor"> dépannage d’urgence</span>{' '}
            depuis 1998
          </h1>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-600">
            Avec plus de
            <span className="text-gray-900"> 20 ans d’expérience</span> en tant
            qu’artisans serruriers, nous sommes fiers de la confiance que nos
            clients nous expriment. Leur satisfaction hors normes à travers deux
            décennies est le reflet du savoir-faire de notre équipe composée
            uniquement de collaborateurs
            <span className="text-gray-900">professionnels</span> et
            <span className="text-gray-900">expérimentés.</span>
          </p>
        </div>

        <div className=" mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className=" text-2xl font-extrabold  tracking-tight sm:text-3xl text-primarycolor">
              A propos de Super Serrurier
            </h2>

            <div className="text-lg text-gray-600">
              <p className="mt-3 ">
                Super serrurier est une entité qui appartient à l’entreprise
                Stop Cambriolage Sàrl. Notre gamme de services diversifiés
                incarnant{' '}
                <span className="text-gray-900">la qualité suisse</span>,
                jumelée à la proximité de notre clientèle, nous permet de jouir
                d’une réputation de qualité et de fiabilité dans le domaine.
              </p>
              <p className="mt-3 ">
                De plus, grâce à nos collaborateurs couvrant toute la Suisse
                Romande, et étant disponibles en permanence, nous sommes fiers
                d’offrir des services d’urgences en moins de trente minutes. Et
                tout ça, au
                <span className="text-gray-900">
                  {' '}
                  meilleur prix sur le marché !
                </span>
              </p>
            </div>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="230"
              fill="none"
              viewBox="0 0 784 230"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="784"
                height="230"
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              ></rect>
            </svg>
            <img
              className="relative mx-auto"
              width="490"
              src="https://res.cloudinary.com/dnzgotmwo/image/upload/v1606606743/super/Images_super_serrurier_sous_slide_4_ebcqpy.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
